
import { defineComponent, Ref, ref, watch, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "PublicSurvey",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const route = useRoute();
    const { bus } = useBus();
    const surveyTitle = ref(null);
    const respondentInfo = ref(null);
    const surveyAnswer = ref([]);

    const loadSurveyAnswer = async () => {
      try {
        const survey_id = route.params.survey_id;
        const mobile_no = route.params.mobile_no;

        console.log(mobile_no);

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_SUERVEY_ANSWER_SHEET,
            {
              survey_id: survey_id,
              mobile_no: mobile_no,
            }
        );

        console.log(response);

        surveyAnswer.value = response.data.data.answers;
        respondentInfo.value = response.data.data.respondent_info;

      } catch (error) {
        console.error("Error loading data for update:", error);
      }
    };

    watch(
        () => route.params.survey_id,
        (newData) => {
          if (newData) {
            loadSurveyAnswer();
          }
        }
    );

    onMounted(() => {
      if (route.params.survey_id) {
        loadSurveyAnswer();
      }

    });

    return {
      surveyTitle,
      surveyAnswer,
      respondentInfo
    };
  },
});
